<template>
  <v-container>
    <v-row v-if="ready">
      <spinner style="margin: 300px 500px;"></spinner>
    </v-row>
    <!--    <v-card-->
    <!--            class="d-flex pa-2 mx-8"-->
    <!--            outlined-->
    <!--            height="200"-->
    <!--            tile-->
    <!--            style="margin-top: 60px"-->
    <!--    >-->
    <!--      <div>-->
    <!--        I'm a flexbox container!-->
    <!--      </div>-->
    <!--    </v-card>-->
    <div v-else>
      <div class="ml-3 mb-n2">
        <v-breadcrumbs
          :items="items"
          :to="items.link"
          divider=" - "
        ></v-breadcrumbs>
      </div>

      <v-row class="d-flex justify-center">
        <v-container fluid grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 sm5 class="ml-7">
              <v-card
                height="300"
                width="400"
                class="pa-2 blue-grey--text"
                outlined
                tile
              >
                <ItemDescription
                  first="Organization Name: "
                  :second="details.name"
                />
                <hr />
                <ItemDescription
                  first="Branch: "
                  :second="details.organizationId.name"
                />
                <ItemDescription
                  first="Telephone: "
                  :second="details.telephoneNumber"
                />
                <ItemDescription
                  first="Location: "
                  :second="details.location"
                />
                <ItemDescription
                  first="Region: "
                  :second="details.region"
                /> </v-card></v-flex
            ><v-flex xs12 sm6>
              <v-card
                height="300"
                width="670"
                class="pa-2"
                style="margin-left:20px"
                outlined
                tile
              >
                <h5 class="blue-grey--text pa-2">Recent Activities</h5>
              </v-card></v-flex
            ></v-layout
          >
        </v-container>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import ItemDescription from "../../../components/ItemDescription";

import * as axios from "axios";
import Spinner from "vue-simple-spinner";
export default {
  name: "ViewBranch",
  components: { ItemDescription, spinner: Spinner },
  data: () => ({
    details: {},
    ready: true,
    name: "",
    location: "",
    phone: "",
    region: "",
    items: [
      {
        text: "Branches",
        disabled: false,
        name: "master.branches"
      },
      {
        text: "Branch",
        disabled: false
      }
    ]
  }),

  methods: {
    getBranch() {
      let id = this.$route.params.id;
      console.log(id);
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/branches/${id}`
      })
        .then(response => {
          console.log(response);
          if (response.status === 200) {
            this.details = response.data;
            this.ready = false;
            //console.log(response);
          }
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.$router.replace({ name: "/login" });
          } else {
            //console.log(err);
          }
        });
    }
  },
  created() {
    this.getBranch();
  }
};
</script>

<style scoped></style>
