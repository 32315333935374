<template>
  <div
    style="display: flex; flex-direction: row; flex-wrap: wrap; align-content: center; justify-content: space-between"
    class="pa-2"
  >
    <h5>{{ first }}</h5>
    <h5>{{ second }}</h5>
  </div>
</template>

<script>
export default {
  name: "ItemDescription",
  props: ["first", "second"]
};
</script>

<style scoped></style>
